import React, { useCallback } from "react";
import css from "./K2.module.css";

import help from "./assets/help.png";
import stop from "./assets/stop.png";
import DropDown from "../../components/DropDown";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useSubdomain } from "../../../../common/util/useSubdomain";
import { WaggleLogo } from "../../../waggle_teacher/containers/TopNav/WaggleLogo";



export const K2Header = ({ grade, subject }) => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation()
  const language = params.language || inferLanguage(location.pathname)
  const {isBoth, defaultSubject} = useSubdomain();


  const subjectHandler = useCallback(
    (index) => {
      history.push(`/home-${index == 0 ? "ela" : "math"}-${grade}/${index === 2 ? "es" : "en"}`);
    },
    [grade, history]
  );

  const gradeToSelectIndex = (grade) => {
    if (grade === 1) return 0;
    return grade - 2;
  };

  const gradeHandler = useCallback(
    (index) => {
      if (index === 0) {
        history.push(`/home-${subject}-1/${language}`);
      } else {
        history.push(`/home-${subject}-${index + 2}`);
      }
    },
    [history, language, subject]
  );

  return (
    <div className={css.header}>
      <WaggleLogo />
      <div className={css.dropdownContainer}>
        <div className={css.dropdown}>
          {isBoth && (
          <DropDown
            width="220px"
            selectedIndex={subject == "ela" ? 0 : language === "es" ? 2 : 1}
            selectIndex={subjectHandler}
            data={[
              { text: "Training60 - ELA" },
              { text: "Training60 - Math" },
              { text: "Spanish60 - Math" },
            ]}
          />
          )}
        </div>
        <div className={css.dropdown}>
          <DropDown
            width="190px"
            selectedIndex={gradeToSelectIndex(grade)}
            selectIndex={gradeHandler}
            data={[
              { text: "Grade - 1" },
              { text: "Grade - 3" },
              { text: "Grade - 4" },
              { text: "Grade - 5" },
              { text: "Grade - 6" },
              { text: "Grade - 7" },
              { text: "Grade - 8" },
            ]}
          /*rolloverTipTitle="Choose Subject"
                  rolloverTip="Choose english or math."*/
          />
        </div>
      </div>
      <h1>Welcome, Student</h1>
      <div className={css.headerSpacer} />
      <ul className={css.rightIcons}>
        <li>
          <img src={help} />
        </li>
        <li>
          <img src={stop} />
        </li>
      </ul>
    </div>
  );
};


// This is a hack because our route structure was cobbled together after the fact
const inferLanguage = (path) => {
  if (path.endsWith("es")) return 'es';
  return 'en'
}