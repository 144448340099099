export const useSubdomain = () => {
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  const defaultSubject = subdomain === "ela" ? "ela" : "math";
  const isMath = subdomain !== "ela";
  const isEla = subdomain !== "math";
  const isBoth = isMath && isEla;

  const calculatedSubDomain = isBoth ? "www" : defaultSubject;
  
  return {
    subdomain,
    isMath,
    isEla,
    isBoth,
    defaultSubject,
    calculatedSubDomain
  };
};
